import React from 'react';

import Dunkin from 'mocks/webflow/dunkin';

import 'styles/webflow/normalize.scss';
import 'styles/webflow/webflow.scss';
import 'styles/webflow/dunkindonuts-survey.webflow.scss';

const DunkinPage = () => {
  return (
    <div
      className="webflow webflow-dunkin"
      dangerouslySetInnerHTML={{ __html: Dunkin }}
    ></div>
  );
};

export default DunkinPage;
